<template>
  <div class="lg:mt-5 lg:px-4 max-w-2xl mx-auto side-nav short">
    <vue-headful
      title="Settings - Tax | Octoa"
    />
    <div class="flex flex-wrap mb-5">
      <div class="w-full lg:w-1/4">
        <setting-menu />
      </div>
      <div class="w-full lg:w-3/4">
        <div class="bg-white h-full mx-4 my-4 px-4 py-4 lg:mx-0 lg:my-0 lg:px-8 lg:py-8 border-grey-lighter border rounded">
          <h2 class="font-bold mb-5">
            Tax classes
          </h2>

          <!-- Draggable -->
          <Container
            lock-axis="y"
            drag-handle-selector=".drag"
            @drop="onDrop"
          >
            <Draggable
              v-for="(tax, key) in taxes"
              v-if="tax.reference != 'delete'"
              :key="key"
            >
              <div class="draggable-item">
                <div
                  class="drag mb-5"
                  :class="{'pt-5 border-solid border-t border-grey-lighter' : key > 0}"
                >
                  <img
                    src="@/assets/img/icons/scroll.svg"
                    width="11"
                    class="align-middle mr-4 cursor-pointer"
                  >
                  VAT <span class="mx-3"><input
                    v-model="tax.value"
                    type="number"
                    class="text-right"
                  ></span> %

                  <div
                    class="float-right mt-3"
                    @click="removeTax(tax)"
                  >
                    <img
                      src="@/assets/img/icons/delete.svg"
                      width="18"
                    >
                  </div>
                </div>
              </div>
            </Draggable>
          </Container>
          <!-- End Draggable -->

          <a
            class="text-green underline cursor-pointer pt-5"
            @click="addTax"
          >Add tax rate</a>

          <h2 class="font-bold my-5 pt-5 border-solid border-t border-grey-lighter">
            Tax calculations
          </h2>

          <p class="mb-3 leading-normal">
            Are your default prices inclusive of the tax or is the tax added on top of your default prices. (This can still be changed per proposal and invoice) 
            <br>

            <span class="mt-2 mb-5 block  leading-normal text-xs text-grey-darker">*Various countries and cases have a different way of calculating tax. For example, in Europe when servicing consumers your prices have to be including tax. When in the US the tax is added on top of the prices.</span>
          </p>
          <div class="mb-3">
            <v-checkbox
              id="includeTaxInPrice"
              v-model="includeTaxInPrice"
              :checked="includeTaxInPriceCheck"
              label="Tax on top of prices"
            />
          </div>
        </div>
      </div>

      <div class="w-full px-4 mt-8 lg:px-0 lg:mt-4 mb-10 text-right">
        <v-button @click="save">
          Save
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import { applyDrag } from '@/utils/helpers'
import auth from '@/auth'

  export default {
    name: 'SettingsTax',
    components: {
      Container,
      Draggable
    },
    data(){
      return {
        isSaving: false,
        user: auth.user(),
        taxes: [],
        includeTaxInPrice: false,
      }
    },
    computed:{
      includeTaxInPriceCheck(){

        if(this.includeTaxInPrice == 1){

          return true
        }

        return false
      }
    },
    async mounted(){
      await this.refresh()
    },
    methods: {
      addTax(){
        this.taxes.push({
         id: new Date().getTime(),
         key: 'tax',
         value: 0,
         reference: null,
        })
      },
      removeTax(tax){
        tax.reference = 'delete'
      },
      onDrop (dropResult) {
        this.taxes = applyDrag(this.taxes, dropResult)
      },
      save(){
        this.$validator.validate().then(result => {
          if(result){
            this.doSave()
          }
        })
      },
      async doSave(){
        this.saving = true

        const postData = {
          taxes: this.taxes,
          includeTaxInPrice: this.includeTaxInPrice,
        }

        try {
          await this.$store.dispatch('company/patchTax', postData)
          this.$toasted.global.general_success({
            message : 'Company tax settings updated.'
          })
          await this.refresh()
        } catch(e) {
          this.$toasted.global.api_error(e)
        }

        this.saving = false

      },
      async refresh() {

        this.taxes = []
        let that = this
        try {
          await this.$store.dispatch('company/refreshCompanyDetails')

          const company = this.$store.getters['company/details']

          if(company.taxes){
            const taxes = Object.values(company.taxes)
            for (const tax of taxes) {
              that.taxes.push({
               id: tax.id,
               key: 'tax',
               value: tax.value,
               reference: null,
              })
            }
          }

          this.includeTaxInPrice = (company.includeTaxInPrice == 0) ? false : true
          auth.refreshUser()

        } catch(e) {
          this.$toasted.global.api_error(e)
        }
      },
    },
  }
</script>
